<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      ref="myTable"
      :loading="loader"
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      hide-pagination
      :rows-per-page-options="[0]"
      :filter="filter"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      :visibleColumns="visibleColumns"
      v-model:pagination="pagination"
      virtual-scroll
    >
      <template v-slot:top-left>
        <div class="q-mr-xl">
          <SelecaoEmpresas
            v-model:empresaId="empresa"
            @selected="selecionarEmpresa($event)"
          />
        </div>
        <div class="q-mr-xl">
          <SelecaoMarcas
            v-model:marcaId="marca"
            @selected="selecionarMarca($event)"
          />
        </div>
        <div class="r7-switch-toggle switch-3 switch-candy noPrint">
          <input id="on" name="state-d" type="radio" checked="" />
          <label for="on" @click="selecionarTipoCLiente('FRANQUIAS')"
            >Franquias</label
          >

          <input id="na" name="state-d" type="radio" checked="checked" />
          <label for="na" @click="selecionarTipoCLiente(null)">Todos</label>
          <input id="off" name="state-d" type="radio" />
          <label for="off" @click="selecionarTipoCLiente('OUTROS')"
            >Outros</label
          >
        </div>
      </template>
      <template v-slot:top-right>
        <q-input
          class="q-ml-sm noPrint"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:header-cell="props">
        <q-th :props="props" class="table-header">
          <q-btn
            size="sm"
            color="white"
            class="noPrint"
            :icon="icon"
            flat
            @click.stop="expandColapseColumns()"
            v-if="props.col.name == 'vencidoTotal'"
          />
          {{ props.col.label }}
        </q-th>
      </template>
      <template v-slot:top-row>
        <q-tr class="totalizador">
          <Totalizador
            :dados="rows"
            :colunas="columns"
            :visibleColumns="visibleColumns"
          />
        </q-tr>
      </template>
      <template v-slot:body-cell-vencido120Mais="props">
        <q-td :props="props" class="r7-link">
          <a @click="abrirVencidos120Mais(props.row)">
            {{ props.value }}
          </a>
        </q-td>
      </template>
      <template v-slot:body-cell-acumulado="props">
        <q-td :props="props" class="r7-link">
          <a @click="abrirAcumulado(props.row)">
            {{ props.value }}
          </a>
        </q-td>
      </template>
    </q-table>
  </div>
</template>
<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import { printTableMixin } from "Mixin/printTableMinx";
import SelecaoMarcas from "./SelecaoMarcas";
import SelecaoEmpresas from "./SelecaoEmpresas";
import Totalizador from "Components/Table/Totalizador";
import { mapActions } from "vuex";

export default {
  name: "DuplicatasEmAbertoListaComponent",
  mixins: [printTableMixin],
  components: { SelecaoMarcas, SelecaoEmpresas, Totalizador },

  data() {
    return {
      loader: true,
      tipoCliente: null,
      marca: null,
      empresa: null,
      dsmarca: null,
      dsempresa: null,
      filter: null,
      icon: "remove",
      colapse: false,
      columnsVencidos: [
        "vencido30",
        "vencido60",
        "vencido90",
        "vencido120",
        "vencido120Mais",
      ],
      visibleColumns: [
        "cliente",
        "tipoCliente",
        "aVencer",
        "vencido30",
        "vencido60",
        "vencido90",
        "vencido120",
        "vencido120Mais",
        "vencidoTotal",
        "acumulado",
        "observacao",
      ],
      visibleColumnsColapse: [
        "cliente",
        "tipoCliente",
        "aVencer",
        "vencidoTotal",
        "acumulado",
        "observacao",
      ],
      pagination: {
        sortBy: "vencido120Mais",
        descending: true,
      },
      oridata: [],
      rows: [],
      columns: [
        {
          label: "Cliente",
          field: "cliente",
          name: "cliente",
          align: "left",
          sortable: true,
          titleTotal: true,
        },
        {
          label: "Tipo Cliente",
          field: "tipoCliente",
          name: "tipoCliente",
          align: "left",
          sortable: true,
        },
        {
          label: "A Vencer",
          field: "aVencer",
          name: "aVencer",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 30",
          field: "vencido30",
          name: "vencido30",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 60",
          field: "vencido60",
          name: "vencido60",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 90",
          field: "vencido90",
          name: "vencido90",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 120",
          field: "vencido120",
          name: "vencido120",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido +120",
          field: "vencido120Mais",
          name: "vencido120Mais",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Total Vencido",
          field: "vencidoTotal",
          name: "vencidoTotal",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Acumulo",
          field: "acumulado",
          name: "acumulado",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Observação",
          field: "observacao",
          name: "observacao",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions("financeiro", ["setEstadoPaginaListarDuplicatasEmAberto"]),

    listarDuplicatas() {
      this.loader = true;
      let marca = this.marca == null ? 999 : this.marca;
      let empresa = this.empresa == null ? 999 : this.empresa;
      api
        .get(
          `/v1/financeiro/duplicatas/emaberto/empresa/${empresa}/marca/${marca}`
        )
        .then((res) => {
          this.rows = res.data;
          this.oridata = res.data;
          this.filtrar();
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    selecionarTipoCLiente(tipoCliente) {
      this.tipoCliente = tipoCliente;
      this.filtrar();
    },
    selecionarMarca(marca) {
      this.marca = marca?.id;
      this.dsmarca = marca?.description;
      this.listarDuplicatas();
    },
    selecionarEmpresa(empresa) {
      this.empresa = empresa?.id;
      this.dsempresa = empresa?.description;
      this.listarDuplicatas();
    },
    filtrar() {
      const filterRow = (row) => {
        let flag = true;
        if (this.tipoCliente && this.tipoCliente !== row.tipoCliente) {
          flag = false;
        }
        return flag;
      };
      this.rows = this.oridata.filter((f) => filterRow(f));
    },
    expandColapseColumns() {
      if (this.colapse) {
        this.colapse = false;
        this.icon = "remove";
        this.visibleColumns = [...this.visibleColumns, ...this.columnsVencidos];
      } else {
        this.colapse = true;
        this.icon = "add";
        this.visibleColumns = [...this.visibleColumnsColapse];
      }
    },
    abrirVencidos120Mais(row) {
      const data = {
        vencido120Mais: this.$filters.formatWithoutDecimal(row.vencido120Mais),
        component: "vencidos120diasmais",
      };
      this.chamarPaginaDetalhe(data, row);
    },
    abrirAcumulado(row) {
      console.log(row);
      const data = {
        aVencer: this.$filters.formatWithoutDecimal(row.aVencer),
        vencido30: this.$filters.formatWithoutDecimal(row.vencido30),
        vencido60: this.$filters.formatWithoutDecimal(row.vencido60),
        vencido90: this.$filters.formatWithoutDecimal(row.vencido90),
        vencido120: this.$filters.formatWithoutDecimal(row.vencido120),
        vencido120Mais: this.$filters.formatWithoutDecimal(row.vencido120Mais),
        acumulado: this.$filters.formatWithoutDecimal(row.acumulado),
        component: "acumulado",
      };
      this.chamarPaginaDetalhe(data, row);
    },
    chamarPaginaDetalhe(params, row) {
      const data = {
        cliente: row.cliente,
        marca: this.marca == null ? 999 : this.marca,
        empresa: this.empresa == null ? 999 : this.empresa,
        dsempresa: this.dsempresa == null ? "Todas empresas" : this.dsempresa,
        ...params,
      };

      this.setEstadoPaginaListarDuplicatasEmAberto(data);

      this.$router.push({
        name: "listaduplicatasdetalhes",
        query: data,
      });
    },
  },
  mounted() {
    const dados =
      this.$store.getters["financeiro/getEstadoPaginaListarDuplicatasEmAberto"];
    if (dados) {
      this.empresa = dados.empresa;
      this.marca = dados.marca;
      this.dsempresa = dados.dsempresa;
      this.setEstadoPaginaListarDuplicatasEmAberto();
    }

    this.listarDuplicatas();
  },
};
</script>
