// messages.js
export default {
  general: "General",
  home: "Home",
  overview: "Overview",
  customizer: "Customizer",
  applications: "Applications",
  features: "Features",
  components: "Components",
  salesAndVisitStats: "Sales & Visits Stats",
  orderAndProjectStats: "Order & Projects Stats",
  fitnessStats: "Fitness Stats",
  supportAndUsefulWidgets: "Support & Useful Widgets",
  dashboard: "Dashboard",
  dashboardOverview: "Dashboard Overview",
  dashboardv1: "Dashboard V1",
  dashboardv2: "Dashboard V2",
  widgets: "Widgets",
  horizontalMenu: "Horizontal Menu",
  pages: "Pages",
  gallery: "Gallery",
  pricing: "Pricing",
  pricing1: "Pricing 1",
  pricing2: "Pricing 2",
  blank: "Blank",
  session: "Session",
  signUp1: "Sign Up 1",
  signUp2: "Sign Up 2",
  login1: "Login 1",
  login2: "Login 2",
  lockScreen: "Lock Screen",
  uiElements: "UI Elements",
  buttons: "Buttons",
  cards: "Cards",
  checkbox: "Checkbox",
  carousel: "Carousel",
  chips: "Chips",
  datepicker: "Datepicker",
  dialog: "Dialog",
  grid: "Grid",
  input: "Input",
  list: "List",
  menu: "Menu",
  progress: "Progress",
  radio: "Radio",
  select: "Select",
  slider: "Slider",
  snackbar: "Snackbar",
  tabs: "Tabs",
  toolbar: "Toolbar",
  tooltip: "Tooltip",
  timepicker: "Timepicker",
  forms: "Forms",
  formValidation: "Form Validation",
  stepper: "Stepper",
  charts: "Charts",
  vueChartjs: "Vue Chartjs",
  vueEcharts: "Vue Echarts",
  icons: "Icons",
  themify: "Themify",
  material: "Material",
  tables: "Tables",
  standard: "Standard",
  slots: "Slots",
  selectable: "Selectable",
  searchRow: "Search Row",
  maps: "Maps",
  googleMaps: "Google Maps",
  leafletMaps: "Leaflet Maps",
  jvectorMap: "Jvector Map",
  inbox: "Inbox",
  users: "Users",
  userProfile: "User Profile",
  usersList: "Users List",
  calendar: "Calendar",
  editor: "Editor",
  quillEditor: "Quill Editor",
  wYSIWYG: "WYSIWYG",
  vue2Dragula: "Vue2 Dragula",
  dragAndDrop: "Drag And Drop",
  vueDraggable: "Vue Draggable",
  draggableResizeable: "Draggable Resizeable",
  chat: "Chat",
  todo: "Todo",
  modules: "Modules",
  user: "User",
  miscellaneous: "Miscellaneous",
  promo: "Promo",
  connections: "Connections",
  follow: "Follow",
  unfollow: "Unfollow",
  basicValidation: "Basic Validation",
  validationWithSubmitAndclear: "Validation with submit & clear",
  submit: "Submit",
  clear: "Clear",
  step1: "Step 1",
  step2: "Step 2",
  step3: "Step 3",
  continue: "Continue",
  cancel: "Cancel",
  totalEarnings: "Total Earnings",
  onlineRevenue: "Online Revenue",
  offlineRevenue: "Offline Revenue",
  marketingExpenses: "Marketing Expenses",
  newCustomers: "New Customers",
  visitAndSalesStatistics: "Visit & Sales Statistics",
  collapse: "Collapse",
  reload: "Reload",
  close: "Close",
  bandwidthUsage: "Bandwidth Usage",
  shoppingCart: "Shopping Cart",
  totalDownloads: "Total Downloads",
  productSales: "Product Sales",
  projectStatus: "Project Status",
  averageSteps: "Average Steps",
  todaysDistance: "Today's Distance",
  todaysStep: "Today's Step",
  todaysGoal: "Today's Goal",
  calories: "Calories",
  weeklySummary: "Weekly Summary",
  supportTickets: "Support Tickets",
  todoList: "To Do List",
  newsletterCampaign: "Newsletter Campaign",
  currentVisitors: "Current Visitors",
  newMembers: "New Members",
  addNewBlog: "Add New Blog",
  add: "Add",
  logOut: "Log Out",
  totalAppMemory: "Total App Memory",
  totalMemoryUsed: "Total Memory Used",
  unreadMail: "12 Unread Mail",
  feedback: "Feedback",
  flatButton: "Flat Button",
  raisedButton: "Raised Button",
  gradientButton: "Gradient Button",
  buttonDropdownVariants: "Button Dropdown Variants",
  buttonToggle: "Button Toggle",
  icon: "Icon",
  normal: "Normal",
  disabled: "Disabled",
  floating: "Floating",
  loaders: "Loaders",
  sizing: "Sizing",
  outline: "Outline",
  round: "Round",
  block: "Block",
  search: "Search",
  searching: "Searching",
  checkboxesBoolean: "Checkboxes Boolean",
  checkboxesArray: "Checkboxes Array",
  checkboxesStates: "Checkboxes - States",
  checkboxesColors: "Checkboxes - Colors",
  switches: "Switches",
  default: "Default",
  customTransition: "Custom Transition",
  customDelimiter: "Custom Delimiter",
  hideControls: "Hide Controls",
  hideDelimiters: "Hide Delimiters",
  colored: "Colored",
  label: "Label",
  closeable: "Closeable",
  datePickerHorizontal: "Date Picker Horizontal",
  datePickerVertical: "Date Picker Vertical",
  datePickersInDialogAndMenu: "Date Pickers- In Dialog And Menu",
  datePickersWithModal: "Date Pickers With Modal",
  datePickersFormattingDate: "Date Pickers - Formatting Date",
  simpleDialogs: "Simple Dialogs",
  withoutActivator: "Without Activator",
  scrollable: "Scrollable",
  listOfAllMaterialIcons: "List Of All Material Icons",
  arrowsAndDirectionsIcons: "ARROWS & DIRECTION ICONS",
  webAppIcons: "WEB APP ICONS",
  CONTROLICONS: "CONTROL ICONS",
  TEXTEDITOR: "TEXT EDITOR",
  LAYOUTICONS: "LAYOUT ICONS",
  BRANDICONS: "BRAND ICONS",
  newClients: "New Clients",
  recurringClients: "Recurring Clients",
  bounceRate: "Bounce Rate",
  pageViews: "Page Views",
  usefulWidgets: "Useful Widgets",
  sale: "Sale",
  sales: "Sales",
  invoices: "Invoices",
  referrals: "Referrals",
  serverLoad: "Server Load",
  recentSale: "Recent Sale",
  supportRequest: "Support Request",
  productSale: "Product Sale",
  viewAll: "VIEW ALL",
  barChart: "Bar Chart",
  polarAreaChart: "Polar Area Chart",
  lineChart: "Line Chart",
  radarChart: "Radar Chart",
  doughnutChart: "Doughnut Chart",
  bubbleChart: "Bubble Chart",
  gradientLineChart: "Gradient Line Chart",
  pieChart: "Pie Chart",
  funnelChart: "Funnel Chart",
  loginNow: "Login Now",
  createAccount: "Create Account",
  termsOfService: "Terms of Service",
  bySigningUpYouAgreeTo: "By signing up you agree to",
  loginToAdmin: "Login To Admin",
  enterUsernameAndPasswordToAccessControlPanelOf: "Enter username and password",
  // "Enter username and password to access control panel of",
  havingAnAccount: "Having an account?",
  quickLinks: "Quick Links",
  plans: "Plans",
  chooseThePlanThatWorksForYou: "Choose the plan that works for you.",
  monthly: "Monthly",
  yearlyGet2MonthExtra: "Yearly ( get 2 month extra)",
  basic: "Basic",
  pro: "Pro",
  advanced: "Advanced",
  basicFree: "Basic (Free)",
  startToBasic: "Start To Basic",
  upgradeToPro: "Upgrade To Pro.",
  upgradeToAdvance: "Upgrade To Advance",
  comparePlans: "Compare Plans",
  frequentlyAskedQuestions: "Frequently Asked Questions",
  defaultInput: "Default Input",
  activator: "Activator",
  hover: "Hover",
  menus: "Menus",
  indeterminate: "Indeterminate",
  sizeAndWidth: "Size & Width",
  rotate: "Rotate",
  determinate: "Determinate",
  buffer: "Buffer",
  radiosDefault: "Radios Default",
  radiosDirectionRow: "Radios Direction Row",
  radiosDirectionColumn: "Radios Direction Column",
  radiosColors: "Radios - Colors",
  switchesColors: "Switches - Colors",
  continuous: "Continuous",
  discrete: "Discrete",
  customColors: "Custom Colors",
  editableNumericValue: "Editable numeric value",
  contextualSnackbar: "Contextual snackbar",
  showSnackbar: "Show Snackbar",
  centeredTabs: "Centered Tabs",
  toolbarTabs: "Toolbar Tabs",
  theDefaultColorToolbar: "The Default Color Toolbar",
  appBar: "App Bar",
  appBarWithExtension: "App Bar With Extension",
  visibility: "Visibility",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  toggle: "Toggle",
  timePicker: "Time Picker",
  timePickerInDialogAndMenu: "Time Picker - In Dialog And Menu",
  itemsAndHeaders: "Items And Headers",
  selectableRows: "Selectable Rows",
  headerCell: "Header Cell",
  expand: "Expand",
  recentChat: "Recent Chat",
  previousChats: "Previous chats",
  addNewItems: "Add New Items",
  addToDo: "Add To Do",
  yes: "Yes",
  ok: "Ok",
  activeUsers: "Active Users",
  addNew: "Add New",
  readMore: "Read More",
  assignNow: "Assign Now",
  totalRequest: "Total Request",
  new: "New",
  pending: "Pending",
  update: "Update",
  updated10MinAgo: "Updated 10 min ago",
  addNewCustomer: "Add New Customer",
  name: "Name",
  email: "Email",
  editMember: "Edit Member",
  scheduleDate: "Schedule Date",
  title: "Title",
  newEmails: "New Emails",
  newEmail: "New Email",
  employeePayroll: "Employee Payroll",
  forMostOfTheUsers: "For most of the users",
  choosePlan: "Choose Plan",
  mega: "Mega",
  master: "Master",
  forDeveloper: "For developer",
  forLargeEnterprises: "For large enterprises",
  composeEmail: "Compose Email",
  mailboxes: "Mailboxes",
  folders: "Folders",
  inbox5: "Inbox (5)",
  draft: "Draft",
  starred: "Starred",
  sentMessages: "Sent Messages",
  spam: "Spam",
  delete: "Delete",
  work: "Work",
  personal: "Personal",
  manageFolders: "Manage Folders",
  contacts: "Contacts",
  settings: "Settings",
  themeOptions: "Theme Options",
  darkMode: "Dark Mode",
  collapseSidebar: "Collapse Sidebar",
  boxLayout: "Box Layout",
  rtlLayout: "Rtl Layout",
  backgroundImage: "Background Image",
  sidebarFilters: "Sidebar Filters",
  danger: "Danger",
  primary: "Primary",
  warning: "Warning",
  success: "Success",
  info: "Info",
  dailySales: "Daily Sales",
  trafficChannel: "Traffic Channel",
  campaignPerformance: "Campaign Performance",
  goToCampaign: "Go To Campaign",
  fullScreen: "Full Screen",
  ecommerce: "E-Commerce",
  shopWithAlgolia: "Shop With Algolia",
  cart: "Cart",
  total: "Total",
  totalPrice: "Total Price",
  viewCart: "View Cart",
  checkout: "Checkout",
  apply: "Apply",
  noItemsFound: "No Items Found",
  billingDetails: "Billing Details",
  placeOrder: "Place Order",
  addNewCard: "Add New Card",
  devicesShare: "Devices Share",
  customerOverview: "Customer Overview",
  reviews: "Reviews",
  weeklySales: "Weekly Sales",
  recentOrders: "Recent Orders",
  categorySales: "Category Sales",
  webAnalytics: "Web Analytics",
  topSelling: "Top Selling",
  social: "Social",
  newPost: "New Post",
  publish: "Publish",
  magazine: "Magazine",
  editUser: "Edit User",
  addNewUser: "Add New User",
  dark: "Dark",
  light: "Light",
  recentUsers: "Recent Users",
  activityAroundWorld: "Activity Around World",
  adsIncome: "Ads Income",
  recentComments: "Recent Comments",
  recentNotifications: "Recent Notifications",
  messages: "Messages",
  edit: "Edit",
  topAuthors: "Top Authors",
  marketingCampaign: "Marketing Campaign",
  article: "Article",
  websiteTraffic: "Website Traffic",
  agency: "Agency",
  recent: "Recent",
  trending: "Trending",
  totalPageViews: "Total Page Views",
  impressions: "Impressions",
  deviceSeparations: "Device Separations",
  browserStatics: "Browser Statics",
  totalDownloading: "Total Downloading",
  notifications: "Notifications",
  totalBookmarked: "Total Bookmarked",
  itemsDownloads: "Items Downloads",
  itemsUploaded: "Items Uploaded",
  uploadImage: "Upload Image",
  totalAnalytics: "Total Analytics",
  hotKeywords: "Hot Keywords",
  MaleUsers: "Male Users",
  serverStatus: "Server Status",
  purchaseVuely: "Purchase Vuely",
  letsGetInTouch: "Lets Get In Touch",
  maleUsers: "Male Users",
  femaleUsers: "Female Users",
  androidUsers: "Android Users",
  iOSUsers: "iOS Users",
  advance: "Advance",
  routerAnimation: "Router Animation",
  salesAndEarning: "Sales And Earning",
  netProfit: "Net Profit",
  totalExpences: "Total Expences",
  photos: "Photos",
  adCampaignPerfomance: "Ad Campaign Perfomance",
  profitShare: "Profit Share",
  topSellingTheme: "Top Selling Theme",
  newRequest: "New Request",
  followers: "Followers",
  mailbox: "Mailbox",
  sent: "Sent",
  birthdays: "Birthdays",
  events: "Events",
  newsletter: "Newsletter",
  lastMonth: "Last Month",
  allTime: "All Time",
  composeNewEmail: "Compose New Email",
  activity: "Activity",
  message: "Message",
  selectTheme: "Select Theme",
  continueShopping: "Continue Shopping",
  proceedToCheckout: "Proceed To Checkout",
  headerFilters: "Header Filters",
  blog: "Blog",
  signUp: "Sign Up",
  login: "Login",
  news: "News",
  topHeadlines: "Top Headlines",
  visitors: "Visitors",
  subscribers: "Subscribers",
  twitterFeeds: "Twitter Feeds",
  extensions: "Extensions",
  imageCropper: "Image Cropper",
  videoPlayer: "Video Player",
  dropzone: "Dropzone",
  baseConfig: "Base Config",
  audioTrackAndPlaysInline: "Audio Track And Plays Inline",
  hlsLive: "HLS Live",
  forgotPassword: "Forgot Password",
  resetPassword: "Reset Password",
  generatePassword: "Generate Password",
  backToSignIn: "Back To Sign In",
  enterYourEmailToSendYouAResetLink:
    "Enter your email to send you a reset link",
  pleaseEnterYourPasswordToReset: "Please Enter Your Password To Reset",
  password: "Password",
  remember: "Remember",
  saas: "SAAS",
  overallTrafficStatus: "Overall Traffic Status",
  projectManagement: "Project Management",
  projectTaskManagement: "Project Task Management",
  totalSales: "Total Sales",
  simple: "Simple",
  transitions: "Transitions",
  tax: "Tax",
  expenses: "Expenses",
  images: "Images",
  ratings: "Ratings",
  containAndCover: "Contain And Cover",
  height: "Height",
  fixedRatio: "Fixed Ratio",
  gradients: "Gradients",
  sizeVariants: "Size Variants",
  newIcons: "New Icons",
  webApplication: "Web Application",
  person: "Person",
  file: "File",
  comment: "Comment",
  form: "Form",
  hardware: "Hardware",
  directional: "Directional",
  mapAliases: "Map (Aliases)",
  dateAndTime: "Date And Time",
  groups: "Groups",
  buttonGroups: "Button Groups",
  itemGroups: "Item Groups",
  windows: "Windows",
  onBoarding: "On Boarding",
  accountCreation: "Account Creation",
  treeview: "Treeview",
  customSelectableIcons: "Custom Selectable Icons",
  timelines: "Timelines",
  usage: "Usage",
  scopedSlots: "Scoped Slots",
  asyncItems: "Async Items",
  smallDots: "Small Dots",
  iconDots: "Icon Dots",
  coloredDots: "Colored Dots",
  oppositeSlot: "Opposite Slot",
  denseAlert: "Dense Alert",
  fixedTabs: "Fixed Tabs",
  rightAlignedTabs: "Right Aligned Tabs",
  content: "Content",
  withSearch: "With Search",
  iconsAndText: "Icons And Text",
  grow: "Grow",
  pagination: "Pagination",
  customIcons: "Custom Icons",
  courses: "Courses",
  coursesList: "Courses List",
  courseDetail: "Courses Detail",
  signIn: "Sign In",
  payment: "Payment",
  LearnWithYourConvenience: "Learn With Your Convenience",
  management: "Management",
  design: "Design",
  development: "Development",
  bestseller: "BestSeller",
  addToCart: "Add To Cart",
  popularInstructors: "Popular Instructors",
  moreCoursesFromJamesColt: "More Courses From James Colt",
  whatYoWillLearn: "What you Will learn",
  description: "Description",
  aboutInstructor: "About Instructor",
  userSignIn: "User Sign In",
  guestCheckout: "Guest Checkout",
  continueAsGuest: "Continue as Guest",
  paymentOptions: "Payment Options",
  offerCode: "Offer Code",
  enterCardDetails: "Enter Card Details",
  makePayment: "Make Payment",
  withContent: "With Content",
  customHeight: "Custom Height",
  viewer: "Viewer",
  trade: "Trade",
  upcomingEvents: "Upcoming Events",
  ongoingProjects: "Ongoing Projects",
  viewAllNotifications: "View All Notifications",
  learnMore: "Learn More",
  payments: "Payments",
  taxRates: "Tax Rates",
  addTickets: "Add Tickets",
  projects: "Projects",
  clients: "Clients",
  reports: "Reports",
  officeManagement: "Office Management",
  hotelManagement: "Hotel Management",
  projectGrid: "Project Grid",
  projectList: "Project List",
  projectData: "Project Data",
  videoCallingApp: "Video Calling App",
  hospitalAdministration: "Hospital Administration",
  revenue: "Revenue",
  deals: "Deals",
  transactionList: "Transaction List",
  transferReport: "Transfer Report",
  expenseCategory: "Expense Category",
  allClients: "All Clients",
  recentlyAdded: "Recently Added",
  favourite: "Favourite",
  tradeHistory: "Trade History",
  safeTrade: "Safe Trade",
  exchangeStatistics: "Exchange Statistics",
  quickTrade: "Quick Trade",
  recentTrades: "Recent Trades",
  exchangeRate: "Exchange Rate",
  coinsList: "Coins List",
  currenciesAvailable: "Currencies Available",
  receivedAmount: "Received Amount",
  sentAmount: "Sent Amount",
  totalAmount: "Total Amount",
  walletAddress: "Wallet Address",
  withdraw: "Withdraw",
  deposit: "Deposit",
  bankDetails: "Bank Details",
  phone: "Phone",
  account: "Account",
  buyCryptocurrency: "Buy Cryptocurrency",
  expenditureStats: "Expenditure Stats",
  sellCryptocurrency: "Sell Cryptocurrency",
  transferCryptocurrency: "Transfer Cryptocurrency",
  buyOrSell: "Buy / Sell",
  crm: "CRM",
  crypto: "Crypto",
  supervisor: "Supervisor",
  duration: "Duration",
  netWorth: "Net Worth",
  liveChatSupport: "Live Chat Support",
  marketCap: "Market Cap",
  wallet: "Wallet",

  budget: "Budget",
  department: "Department",
  projectManager: "Project Manager",
  team: "Team",
  status: "Status",
  deadline: "Deadline",
  filesUploaded: "Files Uploaded",
  statistics: "Statistics",
  projectGallery: "Project Gallery",
  projectDetails: "Project Details",
  client: "Client",

  shop: "Shop",
  productDetail: "Product Detail",
  addProduct: "Add Product",
  editProduct: "Edit Product",
  editDetail: "Edit Detail",

  fileInput: "File Input",
  colorPickers: "Color Pickers",
  size: "Size",
  validation: "Validation",
  playground: "Playground",
  chipGroups: "Chip Groups",
  overlays: "Overlays",
  slideGroups: "Slide Groups",
  simpleTable: "Simple Table",
  listItemGroups: "List Item Groups",
  banners: "Banners",
  appBars: "App Bars",

  // Novos
  emailRequired: "Email Required",
  userRequired: "User Required",
  passwordRequired: "Password Required",

  required: "Required",
  maxOccursExceeded: "Maximum occurrences exceeded",
  confirm: "Confirm",
  areYouSureYouWantToDelete: "Are you sure you want to delete?",
  areYouSureYouWantToDeleteThisRecord:
    "Are you sure you want to permanently delete this record?",
  descriptionRequired: "Description is Required",
  successAddedRecord: "Success, record added!",
  successUpdatedRecord: "Success, record updated!",
  successDeletedRecord: "Success, record deleted",
  nameRequired: "Name Required",
  emailMustBeValid: "E-mail must be valid",
  accessLevel: "Access Level",
  save: "Save",
  accessLevelRequired: "Access Level Required",
  mustbelessthan: "must be less than",
  characters: "characters",
  requestSent: "Request Sent",
  confirmPassword: "Confirm Password",
  newPassword: "New Password",
  messagepPasswordNotMatch: "Passwords don't Match",
  patternPassword:
    "The password must be of at least 8 characters long and contain numbers, caps and lower case letters, and punctuation characters.",
  passwordConfirmed: "Password Confirmed",
  passwordLastSet: "Last Password Set",
  badPasswordCount: "Invalid login attempts",
  lastBadPassword: "Last Bad Password",
  admin: "Administration",
  menuList: "Menu list",
  multiLanguage: "Multi Language",
  route: "Route",
  order: "Order",
  orders: "Orders",
  sort: "Sort",
  active: "Active",
  sortRequired: "Required sort",
  pageNotFound: "PAGE NOT FOUND",
  goToHomePage: "GO TO HOMEPAGE",
  accessLevelList: "Access Level List",
  language: "Language",
  invoice: "Invoice",
  customer: "Customer",
  city: "City",
  state: "State",
  comercial: "Comercial",
  customerList: "Customer List",
  typeInformationAboutCustomer:
    "Type information about customer you want to search for",
  customerSelected: "Customer Selected",
  customerProfile: "Customer Profile",
  seller: "Seller",
  manager: "Manager",
  address: "Address",
  billingLast3Years: "Billing Last 3 Years",
  amount: "Amount",
  kilos: "Kilos",
  alternativeUser: "Alternative User",
  ordersSummary: "Orders Summary",
  billing: "Billing",
  billingHistory: "Billing History",
  reportsDetails: "Reports Details",
  reportName: "Report Name",
  tradingName: "Trading Name",
  lastOrder: "Last Order",
  customerSummary: "Customer Summary",
  registrationData: "Registration Data",
  addresses: "Addresses",
  history: "History",
  mainAddress: "Main Address",
  billingAddress: "Billing Address",
  deliveryAddress: "Delivery Address",
  district: "District",
  zipCode: "Zip Code",
  customerType: "Customer Type",
  joinOrders: "Join Orders",
  shippingCompany: "Shipping Company",
  recordType: "Record Type",
  goBack: "Go back",
  orderDate: "Order Date",
  releaseDate: "Release Date",
  promisedDate: "Promised Date",
  deliveryDate: "Delivery Date",
  customerOrder: "Customer Order",
  productId: "Product Id",
  productCode: "Product Code",
  colorCode: "Color Code",
  designCode: "Design Code",
  preInvoice: "Pre Invoice",
  statusPreInvoice: "Status Pre Invoice",
  invoiceDocument: "Invoice Document",
  invoiceIssueDate: "Invoice Issue Date",
  invoiceReleaseDate: "Invoice Release Date",
  ordersList: "Orders List",
  typeOrder: "Type Order",
  immediateDelivery: "Immediate Delivery",
  quantity: "Quantity",
  quantityKg: "Quantity(KG)",
  balance: "Balance",
  orderType: "Order Type",
  reservedQuantityKg: "Reserved Quantity(KG)",
  reservedQuantityQtd: "Reserved Quantity(QTD)",
  reservedQuantityFat: "Reserved Quantity(FAT)",
  partialDelivery: "Partial Delivery",
  combinedOrders: "Combined Orders",
  requirements: "Requirements",
  economicGroup: "Economic Group",
  billOfSale: "Bill of Sale",
  carrier: "Carrier",
  pickupNumber: "Pickup Number",
  pickupDate: "Pickup Date",
  lockReason: "Lock Reason",
  lockDate: "Lock Date",
  grossWeight: "Gross Weight",
  netWeight: "Net Weight",
  volume: "Volume",
  blockedPreInvoices: "Blocked Pre-Invoices",
  scheduledDeliveryDate: "Fecha programada para la entrega",
  businessRules: "Business Rules",
  salesOrder: "Sales Order",
  deliveryWeek: "Delivery Week",
  color: "Color",
  tradingRules: "Trading Rules",
  rawArticleCode: "Raw Article Code",
  rawArticleItem: "Raw Article Item",
  financialInformation: "Financial Information",
  creditLimit: "Credit Limit",
  totalValueOrders: "Total Value Orders",
  totalValueOfBonds: "Total Value of Bonds (Open)",
  overdueAmount: "Overdue Amount",
  creditBalance: "Credit Balance",
  openDuplicates: "Open Duplicates",
  situation: "Situation",
  value: "Value",
  issueDate: "Issue Date",
  dueDate: "Due Date",
  lastDueDate: "Last Due Date",
  expirationDays: "Expiration Days",
  filial: "Filial",
  billingType: "Billing Tyoe",
  thirdPartyCustomer: "Third Party Customer",
  billingBlock: "Billing Block",
  paymentTerms: "Payment Terms",
  commission: "Comission",
  salesChannel: "Sales Channel",
  federativeUnit: "Federative Unit",
  triangularCustomer: "Triangular Customer",
  viaTransport: "Via Transport",
  clientOrderNumber: "Client Order Number",
  complete: "Complete",
  anticipate: "Antecipate",
  askedFor: "Asked For",
  preOrder: "Pre Order",
  conjugate: "Conjugate",
  typist: "Typist",
  billingStatus: "Billing Status",
  orderValue: "Order Value",
  approver: "Approver",
  lots: "Lots",
  historyReport: "History Report",
  orderInquiry: "Order Inquiry",

  reference: "Reference",
  print: "Print",
  finishing: "Finishing",
  unitPrice: "Unit Price",
  unit: "Unit",
  ordered: "Ordered",
  reserved: "Reserved",
  weighted: "Weighted",
  ofItem: "OF",
  billed: "Billed",
  canceled: "Canceled",
  productSituation: "Product Situation",
  quality: "Quality",
  discount: "Discount",

  noDataFound: "No Data Found",

  orderItemList: "Itens List",

  productTagId: "Product Id Tag",
  price: "Price",

  movideskUserList: "Movidesk User List",
  userId: "User Id",

  orderData: "Order Data",
  clientsData: "Client`s Data",
  businessData: "Business Data",
  generalData: "General Data",

  releaseNotes: "Release Notes",

  condition: "Condition",

  sellerSummary: "Seller Summary",
  sellerOrderList: "Seller Order List",
  sellerList: "Seller List",
  typeInformationAboutSeller:
    "Enter the information about the seller you want to search for",
  sellerSelected: "Seller Selected",

  sellerClients: "Seller Clients",
  version: "Version",

  moreOptions: "More Options",

  externalLink: "External Link",
  systemVersion: "System Version",

  movideskTickets: "Movidesk Tickets",

  widgetsList: "Widgets List",
  widget: "Widget",
  component: "Componente",
  id: "Id",

  widgetInformations: "Widget Informations",
  acessLevels: "Acess Levels",

  movideskList: "Movidesk Ticket's List",
  movideskDetail: "Movidesk Ticket's Detail",
  movidesk: "Movidesk",

  fullscreenMode: "Full Screen Mode",
  homePage: "Home Page",
  systemReleases: "System Releases",

  notificationsSetup: "Notifications Setup",
  markMessagesAsRead: "Mark Messages As Read",
  markRead: "Mark Read",
  deleteMessage: "Delete Message",

  changeEmailFrequency: "Change Email Frequency",
  neverSend: "Never Send",
  sendEveryHour: "Send Every Hour",
  sendInstantly: "Send Instantly",
  desktopNotification: "Desktop Notification",
  doNotNotify: "Do Not Notify",
  notify: "Notify",

  messageRegistration: "Message Registration",
  systemMessageList: "System Message List",
  categoryId: "Category Id",

  titleRequired: "Title is Required!",
  successMarkAsRead: "Message Marked As Read!",
  successMarkAllAsRead: "Messages Marked As Read!",
  successUpdateMessageConfigurations:
    "Message Configuration Updated Successfully!",
  noMessagesAvailable: "No Messages Available",

  category: "Category",

  ticketNumber: "Ticket Number",
  subject: "Subject",
  origin: "Origin",
  type: "Type",
  createdBy: "Created by",
  createdDate: "Created Date",
  lastUpdate: "Last Update",

  sampleDepositsList: "Sample Deposits List",
  sampleDeposits: "Sample Deposits",
  depositCode: "Deposit Code",
  depositDescription: "Deposit Description",
  updateDate: "Update Date",
  updateUser: "Update User",
  changeSituation: "Change Situation",
  registerSampleDeposit: "Register Sample Deposit",
  insertDepositCode: "Insert Deposit Code",
  insertDepositDescription: "Insert Deposit Description",
  codeRequired: "Code is Required",
  code: "Code",
  mustBe: "must be of",
  successSubmitedDeposit: "Success, deposit submited!",

  production: "Production",
  registers: "Registers",

  send: "Send",
  copy: "Copy",
  registerPriceList: "Register Price List",
  insertTableName: "Insert Table Name",
  chooseTableStatus: "Choose Table Status",
  chooseEmissionDate: "Choose Emission Date",
  chooseValidityDate: "Choose Validity Date",
  inactive: "Inactive",
  saletePriceList: "Salete Price List",
  purchases: "Purchases",

  copyPriceList: "Copy Price List",
  referenceTable: "Reference Table",

  saletePriceListItem: "Salete Price List Item",
  saletePriceItem: "Salete Price Item",
  table: "Table",
  validity: "Validity",
  baseTable: "Base Table",
  familyStock: "Family Stock",
  familyStockDescription: "Family Stock Description",
  physicalFinish: "Physical Finish",
  physicalFinishDescription: "Physical Finish Description",
  hasPrint: "Has Print?",
  variantOf: "Variant Of",
  variantUntil: "Variant Until",
  quantityOf: "Quantity Of",
  quantityUntil: "Quantity Until",
  tableNumber: "Table Number",
  item: "Item",
  productInfo: "Product Information",
  typeProductId: "Enter the code of the product that you want to search",
  validityDate: "Validity Date",
  isTableActive: "Is Active?",
  emissionDate: "Emision Date",
  stock: "Stock",
  stockPosition: "Stock Position",

  statusRequired: "Status Required",
  dateRequired: "Date Required",
  reduceInfo: "Reduce Info",
  pleaseWait: "Please wait...",

  mustbegreaterthan: "must be greater than",
  ti: "T.I.",
  helpdeskAnalysis: "Help Desk Analysis",

  excelExport: "Excel Export",
  filter: "Filter",

  dashboards: "Dashboards",
  dashboard: "Dashboard",
  dashboardsList: "Dashboards List",
  dashboardStatus: "Dashboard Status",
  groupId: "Group Id",
  dashboardId: "Dashboard Id",
  dashboardInformations: "Dashboard Informations",
  defaultDashboard: "Default Dashboard",

  permission: "Permission",
  allowed: "Allowed",
  notAllowed: "Not Allowed",

  SGTUsersAcessList: "SGT Users Acess List",
  company: "Company",
  sector: "Sector",
  collaboratorsNumber: "Collaborator's Number",
  currentAccess: "Current Access",
  allowedAccess: "Allowed Access",
  temporaryAccess: "Temporary Access",
  available: "Available",
  chooseCompany: "Choose Company",
  chooseBrand: "Choose Brand",
  chooseSubGroup: "Choose SubGroup",
  chooseLicenses: "Choose Licenses",
  all: "All",
  rosset: "Rosset",
  salete: "Salete",
  allAvailable: "Available",
  allUnavailable: "Unavailable ",
  daysOfAccess: "Days of Access",
  actualize: "Actualize",

  updateAvailableLicenses: "Update Available Licenses",

  userSO: "User S.O.",
  terminal: "Terminal",
  logonDate: "Logon Date",
  releaseSession: "Release Session",

  itemsPerPage: "Items Per Page",
  unavailable: "Unavailable",
  rosset: "Rosset",

  totalOfLicenses: "Total of Licenses",
  usedLicenses: "Used",
  availableLicenses: "Available",
  reservedLicenses: "Reserved Licenses",

  effect: "Effect",
  billingvsBudget: "Billing vs Budget",

  online: "Online",
  offline: "Offline",
  vip: "Vip",
  removeConnection: "Remove Connection",
  licenseOwner: "License Owner",
  sharedLicense: "Shared License",
  notSharedLicense: "Not Shared License",
  successRemovedConnection: "Success, connection removed!",

  sharedLicenses: "Shared Licenses",

  sharedLicenseUser: "User to Share License",

  sectors: "Setors",
  allUsers: "All Users",
  numberOfVips: "Number of Vips",
  confirmAction: "Confirm your action",
  areYouSureYouWantToConfirmThisActions:
    "Are you sure you want to confirm this action?",
  companyFilter: "Company Filter",
  userName: "User Name",

  officeRole: "Office Role",
  inactive: "Inactive",

  employeesTotal: "Employees Total",
  employeesTotalWithoutLicense: "Employees Total Without License",
  updateVipLicenses: "Update Vip Licenses",

  nickname: "Nickname",
  updateNickname: "Update Nickname",
  allStatus: "All Status",

  closeNavBar: "Close NavBar",
  openNavBar: "Open NavBar",

  goalsRegistration: "Goals Registration",

  group: "Group",
  month: "Month",
  newRecord: "New Record",
  addRecord: "Add Record",
  insertGroup: "Insert Group",
  insertMonth: "Insert Month",
  chooseSeller: "Choose Seller",
  insertValue: "Insert Value",
  insertKilos: "Insert Kilos",
  groupRequired: "Group Required!",
  monthRequired: "Month Required!",
  sellerRequired: "Seller Required!",
  valueRequired: "Value Required!",
  kiloRequired: "Kilo Required!",
  newGoal: "New Goal",
  editingGoal: "Editing Goal",
  selectGoalToedit: "Select Goal to Edit",
  chooseGoal: "Choose a Goal",
  editGoal: "Edit Goal",
  chooseGroup: "Choose Group",
  chooseMonth: "Choose Month",
  january: "JANUARY",
  february: "FEBRUARY",
  march: "MARCH",
  april: "APRIL",
  may: "MAY",
  june: "JUNE",
  july: "JULY",
  august: "AUGUST",
  september: "SEPTEMBER",
  october: "OCTOBER",
  november: "NOVEMBER",
  december: "DECEMBER",
  uploadGoal: "Upload Goal - Excel",
  downloadModel: "Download Model",
  exit: "Exit",
  saveData: "Save Data",
  goalDescription: "Goal Description",
  period: "Period",
  year: "Year",

  selectXlsxWithGoals: " Select XLSX File with Goals",
  load: "Load",
  yearRequired: "Year is Required",
  periodRequired: "Period is Required",

  line: "Line",

  reviseGoal: "Revise Goal",
  deleteGoal: "Delete Goal",

  amountR$: "Amount (R$)",
  kilosKG: "Kilos (Kg)",
  reviseGoal: "Revise Goal",
  selectGoalTorevise: "Select Goal to Revise",
  unavailableGoal: "Goal Unavailable",
  revisingGoal: "Revising Goal",

  revise: "Revise",
  selectGoalTodelete: "Select Goal to Delete",

  maxValueElevenDigits: "Max Value: 11 digits",

  modificationDate: "Modification Date",
  modificatedBy: "Modificated By",
  documentsQueryRepository: "Documents Query - Repository",
  documentsList: "Documents List",
  addDocument: "Add Document",
  documentDescription: "Document Description",
  area: "Area",
  typeOfAccess: "Type of Access",
  access: "Access",
  document: "Document",
  chooseArea: "Choose Area",
  areaRequired: "Area is Required",
  repository: "Repository",
  areaDescription: "Area Description",
  areaRegistration: "Area Registration",
  areaRegistrationForm: "Area Registration Form",

  updateDescription: "Update Description",
  goalAlreadyExistForTheSelectedYear:
    "Goal already exists for the selected year!",

  invalidDataForSave: "Invalid Data for Save!",
  invalidRegisterPleaseEdit: "Invalid Register, Please Edit!",
  loadingData: "Loading Data...",
  goalRequired: "Goal Required",
  savingData: "Saving Data...",

  selectAction: "Select Action",
  action: "Action",
  actionRequired: "Action is Required",

  addAccess: "Add Access",
  documentAccess: "Access to the Document",
  addUser: "Add User",
  addAccessLevel: "Add Access Level",
  editingDocument: "Editing Document",
  chooseUser: "Choose User",

  editDocument: "Edit Document",
  documentQueries: "Document Queries",
  fileDescription: "File Description",
  fileName: "File Name",

  workplace: "Workplace",
  testTheme: "Test Theme",
  questions: "Questions",
  answers: "Answers",
  question: "Question",
  answer: "Answer",
  candidates: "Candidates",
  candidateResult: "Candidate Result",
  humanResources: "Human Resources",
  questionsAnswers: "Questions and Answers",
  qa: "Q.A.",
  hr: "H.R.",

  workplaceDescription: "Workplace Description",
  chooseWorkplace: "Choose Workplace",
  workplaceRequired: "Workplace Required",
  workplaceRegistration: "Workplace Registration",
  workplaceRegistrationForm: "Workplace Registration Form",

  themeInformations: "Theme Informations",
  chooseTheme: "Choose Theme",
  themeRequired: "Theme Required",

  quiz: "Quiz",
  template: "Template",

  questionRequired: "Question is Required",
  answerRequired: "Answer is Required",
  complement: "Complement",
  multipleChoice: "Multiple Choice",
  questionInformations: "Question Informations",
  answerInformations: "Answer Informations",
  isCorrect: "Is it Correct ?",

  candidateInformations: "Candidate Information",
  job: "Job",
  jobRequired: "Job is Required",
  next: "Next",
  start: "Start",
  loginInformations: "Login Informations",
  redistributeGoal: "Redistribute Goal",
  showNotificationLgpd: "Show LGPD Notification",
  fullTableMode: "Full Table Mode",
  exitFullTableMode: "Exit Full Table Mode",

  costCalculator: "Cost Calculator",
  products: "Products",
  confectionProductRegistration: "Manufacturing Product Registration",
  compositionTypes: "Composition Types",
  compositionList: "Composition List",
  measureUnity: "Measure Unity",
  amountUsed: "Amount Used",
  unitCost: "Unit Cost",

  priceListUpdate: "Price List Update",

  seniorAccessList: "Senior Access List",
  program: "Program",
  schemaName: "Schema Name",
  logonTime: "Logon Time",
  previousExecuteStart: "Previous Execute Start",
  waitTimeInSeconds: "Wait Time in Seconds",

  purchasingGroup: "Purchasing Group",
  controlsBudget: "Controls Budget",
  controlsBudgetConfec: "Controls Manufact. Budget",
  controlsBudgetBrand: "Controls Brand Budget",
  imageConvert: "Image Convert",
  family: "Family",
  subGroup: "Sub Group",
  serie: "Serie",

  priceListStatus: "Price List Status",
  manufacturing: "Manufacturing",
  cadTables: "Tables Order",
  logView: "Log View",
  logTitle: "Log Title",
  tableName: "Table Name",
  fixedExpensesByBrand: "Fixed Expenses by Brand",
  brand: "Brand",
  franchiseRevenue: "Franchise Revenue",
  performanceRetail: "Performance Retail",
  franchise: "Franchise",
  store: "Store",
  omniChannel: "OmniChannel",
  offPremium: "OffPremium",
  finish: "Finish",
  groupsReferences: "Groups References",
  LDAP: "LDAP",
  userSimulate: "User Simulate",

  inventory: "Inventory",
  inventoryEntireNetwork: "Inventory entire network",
  inventorySpecificIP: "Inventory specific IP",
  lastInventoryLog: "Last Inventory Log",
  consoleOutput: "Console",
  run: "Run",

  listOpenInvoices: "List Open Invoices",
  financial: "Financial",
  backupAutomation: "Backup Automation",
  chooseFile: "Choose File",
  chooseSourceDataBase: "Choose Source Database",
  chooseTheDatabaseOfDestination: "Choose The Database Of Destination",
  approvalPurchasingLimits: "Approval of Purchasing Limits",
  approvalAutomaticPurchaseLimits: "Approval of Automatic Purchase Limits",
};
