import api from "Api/cobol";
import { removeAccents } from ".";
import { showSuccess, showError } from "@/notifications/notify";

async function loadDataPagination(url) {
  const result = await api.get(url);
  const jsonOutput = result.data.json_output;

  let rows = [];
  let pagesTotal = 0;
  if (jsonOutput) {
    pagesTotal = jsonOutput.j_total_paginas;
    rows = jsonOutput.registro_lista.map((item) => item.registro_item);
  }
  return { rows, pagesTotal };
}

async function loadDataPaginationJustCobol({ endpoint, chaveNext, acao }) {
  let registroInput;
  if (chaveNext) {
    registroInput = Object.keys(chaveNext).reduce((acc, key) => {
      const newKey = `${key.replaceAll("_nxt", "")}_i`;
      acc[newKey] = chaveNext[key];
      return acc;
    }, {});
  }

  const jsonin = JSON.stringify({
    registroinput: { ...registroInput },
  });

  const params = new URLSearchParams();
  params.append("chavenext", jsonin);
  params.append("acao", acao);
  const result = await api.post(endpoint, params);
  const jsonOutput = result.data.json_output;
  const success = result.data.success;

  if (success === false) {
    showError(result.data.message);
  }

  let rows = [];
  let next = null;
  if (jsonOutput) {
    next = jsonOutput.j_chave_next;
    rows = jsonOutput.registro_lista.map((item) => item.registro_item);
  }

  return { rows, next };
}

async function loadDataJustCobol({ endpoint, onDataLoaded, acao }) {
  const request = {
    endpoint,
    acao,
  };

  // Carrega a primeira parte dos dados
  const { rows, next } = await loadDataPaginationJustCobol(request);

  // Atualiza a interface com a primeira parte dos dados
  onDataLoaded(rows);

  let moreRows = [];

  let flag = (next || false) && rows.length > 0;

  request.chaveNext = next;
  const loadPromises = [];

  // Carrega a segunda parte dos dados em paralelo
  while (flag) {
    const res = await loadDataPaginationJustCobol(request);
    request.chaveNext = res.next;
    flag = (res.next || false) && res.rows.length > 0;
    loadPromises.push(res);
  }

  // Aguarda todas as chamadas assíncronas terminarem
  const results = await Promise.all(loadPromises);
  moreRows = results.reduce((acc, res) => [...acc, ...res.rows], []);

  return moreRows;
}

export async function loadData({
  endpoint,
  onDataLoaded,
  loadJustCobol,
  acao,
}) {
  if (loadJustCobol) {
    return loadDataJustCobol({ endpoint, onDataLoaded, acao });
  }

  const url = (page) => {
    return endpoint.indexOf("?") == -1
      ? `${endpoint}?acao=${acao}&pagina=${page}`
      : `${endpoint}&acao=${acao}&pagina=${page}`;
  };

  // Carrega a primeira parte dos dados
  const { rows, pagesTotal } = await loadDataPagination(url(1));

  // Atualiza a interface com a primeira parte dos dados
  onDataLoaded(rows);

  // Carrega a segunda parte dos dados em paralelo
  const loadPromises = [];
  for (let page = 2; page <= pagesTotal; page++) {
    loadPromises.push(loadDataPagination(url(page)));
  }
  // Aguarda todas as chamadas assíncronas terminarem
  const results = await Promise.all(loadPromises);

  // Concatena e ordena os dados da segunda parte
  const moreRows = results.reduce((acc, res) => [...acc, ...res.rows], []);

  return moreRows;
}

export function sendCobolPost(request) {
  const { registroData, url, acao, metodo } = request;

  const registroInput = Object.keys(registroData).reduce((acc, key) => {
    const newKey = `${key}_i`;
    acc[newKey] = registroData[key];
    return acc;
  }, {});

  const jsonin = JSON.stringify({
    registroinput: { ...registroInput },
  });

  const messageSuccess = () => {
    let msg;
    switch (metodo?.toUpperCase()) {
      case "POST":
        msg = "Registro Incluído com Sucesso!";
        break;
      case "PUT":
        msg = "Registro Atualizado com Sucesso!";
        break;
      case "DELETE":
        msg = "Registro Excluído com Sucesso!";
        break;

      default:
        break;
    }
    return msg;
  };

  //Remove acentuação.
  const stringWithoutAccents = removeAccents(jsonin);

  const params = new URLSearchParams();
  params.append("jsonin", stringWithoutAccents);
  params.append("acao", acao);

  return api
    .post(url, params)
    .then((res) => {
      if (res.data.success == "false") {
        throw new Error(res.data.message);
      }
      showSuccess(messageSuccess());
    })
    .catch((err) => {
      showError(err);
    });
}
