<template>
  <SelectWithFilter
    :id="empresaId"
    :list="listaEmpresas"
    :required="required"
    :label="$t('message.chooseCompany')"
    :rounded="false"
    :filled="true"
    :dense="true"
    @selected="itemSelected($event)"
  />
</template>

<script>
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "SelecaoEmpresas",
  components: { SelectWithFilter },
  emits: ["selected"],

  props: {
    empresaId: Number,
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listaEmpresas: [
        { id: 999, description: "Todas" },
        { id: 76, description: "Filó" },
        { id: 10, description: "Rosset" },
      ],
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
  },
};
</script>
