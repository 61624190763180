<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      ref="myTable"
      :loading="loader"
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      hide-pagination
      :rows-per-page-options="[0]"
      :filter="filter"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      :visibleColumns="visibleColumns"
      virtual-scroll
    >
      <template v-slot:top-left>
        <div class="q-mr-xl">
          <SelecaoEmpresas @selected="selecionarEmpresa($event)" />
        </div>
        <div class="q-mr-xl">
          <SelecaoMarcas @selected="selecionarMarca($event)" />
        </div>
        <div class="r7-switch-toggle switch-3 switch-candy noPrint">
          <input id="on" name="state-d" type="radio" checked="" />
          <label for="on" @click="selecionarTipoCLiente('FRANQUIAS')"
            >Franquias</label
          >

          <input id="na" name="state-d" type="radio" checked="checked" />
          <label for="na" @click="selecionarTipoCLiente(null)">Todos</label>
          <input id="off" name="state-d" type="radio" />
          <label for="off" @click="selecionarTipoCLiente('OUTROS')"
            >Outros</label
          >
        </div>
      </template>
      <template v-slot:top-right>
        <q-input
          class="q-ml-sm noPrint"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:header-cell="props">
        <q-th :props="props" class="table-header">
          <q-btn
            size="sm"
            color="white"
            class="noPrint"
            :icon="icon"
            flat
            @click.stop="expandColapseColumns()"
            v-if="props.col.name == 'vencidoTotal'"
          />
          {{ props.col.label }}
        </q-th>
      </template>
      <template v-slot:top-row>
        <q-tr class="totalizador">
          <Totalizador
            :dados="rows"
            :colunas="columns"
            :visibleColumns="visibleColumns"
          />
        </q-tr>
      </template>
    </q-table>
  </div>
</template>
<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import { printTableMixin } from "Mixin/printTableMinx";
import SelecaoMarcas from "./SelecaoMarcas";
import SelecaoEmpresas from "./SelecaoEmpresas";
import Totalizador from "Components/Table/Totalizador";

export default {
  name: "DuplicatasEmAbertoListaComponent",
  mixins: [printTableMixin],
  components: { SelecaoMarcas, SelecaoEmpresas, Totalizador },

  data() {
    return {
      loader: true,
      tipoCliente: null,
      marca: null,
      empresa: null,
      filter: null,
      icon: "add",
      colapse: true,
      columnsVencidos: [
        "vencido30",
        "vencido60",
        "vencido90",
        "vencido120",
        "vencido120Mais",
      ],
      visibleColumns: [
        "cliente",
        "tipoCliente",
        "aVencer",
        "vencidoTotal",
        "acumulado",
        "observacao",
      ],
      visibleColumnsInitial: [
        "cliente",
        "tipoCliente",
        "aVencer",
        "vencidoTotal",
        "acumulado",
        "observacao",
      ],
      oridata: [],
      rows: [],
      columns: [
        {
          label: "Cliente",
          field: "cliente",
          name: "cliente",
          align: "left",
          sortable: true,
          titleTotal: true,
        },
        {
          label: "Tipo Cliente",
          field: "tipoCliente",
          name: "tipoCliente",
          align: "left",
          sortable: true,
        },
        {
          label: "A Vencer",
          field: "aVencer",
          name: "aVencer",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 30",
          field: "vencido30",
          name: "vencido30",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 60",
          field: "vencido60",
          name: "vencido60",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 90",
          field: "vencido90",
          name: "vencido90",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido 120",
          field: "vencido120",
          name: "vencido120",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Vencido +120",
          field: "vencido120Mais",
          name: "vencido120Mais",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Total Vencido",
          field: "vencidoTotal",
          name: "vencidoTotal",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Acumulo",
          field: "acumulado",
          name: "acumulado",
          format: (val) => this.$filters.formatWithoutDecimal(val),
          total: true,
          classes: "text-right",
          sortable: true,
        },
        {
          label: "Observação",
          field: "observacao",
          name: "observacao",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    listarDuplicatas() {
      this.loader = true;
      let marca = this.marca == null ? 999 : this.marca;
      let empresa = this.empresa == null ? 999 : this.empresa;
      api
        .get(
          `/v1/financeiro/duplicatas/emaberto/empresa/${empresa}/marca/${marca}`
        )
        .then((res) => {
          this.rows = res.data;
          this.oridata = res.data;
          this.filtrar();
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    selecionarTipoCLiente(tipoCliente) {
      this.tipoCliente = tipoCliente;
      this.filtrar();
    },
    selecionarMarca(marca) {
      this.marca = marca.id;
      this.listarDuplicatas();
    },
    selecionarEmpresa(empresa) {
      this.empresa = empresa.id;
      this.listarDuplicatas();
    },
    filtrar() {
      const filterRow = (row) => {
        let flag = true;
        if (this.tipoCliente && this.tipoCliente !== row.tipoCliente) {
          flag = false;
        }
        return flag;
      };
      this.rows = this.oridata.filter((f) => filterRow(f));
    },
    expandColapseColumns() {
      if (this.colapse) {
        this.colapse = false;
        this.icon = "remove";
        this.visibleColumns = [...this.visibleColumns, ...this.columnsVencidos];
      } else {
        this.colapse = true;
        this.icon = "add";
        this.visibleColumns = [...this.visibleColumnsInitial];
      }
    },
  },
  mounted() {
    this.listarDuplicatas();
  },
};
</script>
