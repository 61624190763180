<template>
  <app-section-loader :status="loader"></app-section-loader>

  <SelectWithFilter
    v-if="!loader"
    :id="marcaId"
    :list="listaMarcas"
    :required="required"
    :label="$t('message.chooseBrand')"
    :rounded="false"
    :filled="true"
    :dense="true"
    @selected="itemSelected($event)"
  />
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "SelecaoMarcas",
  components: { SelectWithFilter },
  emits: ["selected"],

  props: {
    marcaId: Number,
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loader: true,
      listaMarcas: [],
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("/v1/financeiro/duplicatas/marcas")
        .then((res) => {
          if (res.data) {
            this.listaMarcas = res.data.map((element) => {
              return {
                id: element.cdMarca,
                description: element.dsMarca,
              };
            });
            this.listaMarcas.push({ id: 999, description: "TODAS" });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
