import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

// modules
import settings from "./modules/settings";
import auth from "./modules/auth";
import clientes from "./modules/clientes";
import representantes from "./modules/representantes";
import comercial from "./modules/comercial";
import admin from "./modules/admin";
import common from "./modules/common";
import compras from "./modules/compras";
import produtos from "./modules/produtos";
import ti from "./modules/ti";
import metas from "./modules/metaComercial";
import repositorio from "./modules/repositorio";
import rh from "./modules/rh";

const store = createStore({
  plugins: [
    createPersistedState({
      // storage: window.localStorage,
      paths: ["clientes", "representantes"],
    }),
  ],
  modules: {
    settings,
    auth,
    clientes,
    representantes,
    comercial,
    admin,
    common,
    compras,
    produtos,
    ti,
    metas,
    repositorio,
    rh,
  },
});

export default store;
