<template>
  <div>
    <q-dialog v-model="isModalActive" has-modal-card :persistent="persistent">
      <q-card
        class="modal-card"
        :style="{ width: width + 'vw' }"
        style="max-width: 100vw"
      >
        <q-card-section class="bg-primary">
          <p class="text-white h3">
            {{ title }}
          </p>
        </q-card-section>
        <q-card-section class="modal-card-body">
          <slot name="search"></slot>
        </q-card-section>
        <q-card-section class="modal-card-body">
          <slot name="body"></slot>
        </q-card-section>
        <q-separator />
        <q-card-section class="modal-card-foot">
          <slot name="button"></slot>
          <slot name="button2"></slot>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: "ModalInfo",
  props: {
    title: {
      type: String,
      default: "",
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 35,
    },
  },
  data() {
    return {
      isModalActive: false,
      highlights: [],
    };
  },

  methods: {
    abrir() {
      this.isModalActive = true;
    },
    fechar() {
      this.isModalActive = false;
    },
  },
};
</script>
