import DuplicatasEmAbertoListaView from "Views/Financeiro/DuplicatasEmAbertoListaView";

const routes = [
  {
    path: "/listaduplicatasemaberto",
    name: "listaduplicatasemaberto",
    component: DuplicatasEmAbertoListaView,
    meta: {
      requiresAuth: true,
      title: "message.listOpenInvoices",
      breadcrumb: ["message.financial"],
    },
  },
];

export default routes;
