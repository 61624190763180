import ListaDePrecosHeader from "Views/Compras/ListaDePrecosHeader";
import ListaDePrecosItem from "Views/Compras/ListaDePrecosItem";
import ListaDePrecosItemDetalhe from "Views/Compras/ListaDePrecosItemDetalhe";
import GruposComprasView from "Views/Compras/GruposComprasView";
import LimitesAprovacaoView from "Views/Compras/LimitesAprovacaoView";
import LimitesAprovacaoAutomaticaView from "Views/Compras/LimitesAprovacaoAutomaticaView";

const routes = [
  {
    path: "/listadeprecosheader",
    name: "listadeprecosheader",
    component: ListaDePrecosHeader,
    meta: {
      requiresAuth: true,
      title: "message.saletePriceList",
      breadcrumb: [
        "message.purchases",
        "message.registers",
        "message.saletePriceList",
      ],
    },
  },
  {
    path: "/listadeprecositem",
    name: "listadeprecositem",
    component: ListaDePrecosItem,
    meta: {
      requiresAuth: true,
      title: "message.saletePriceListItem",
      breadcrumb: [
        "message.purchases",
        "message.registers",
        "message.saletePriceList",
        "message.saletePriceListItem",
      ],
    },
  },
  {
    path: "/listadeprecositemdetalhe/:id",
    name: "listadeprecositemdetalhe",
    component: ListaDePrecosItemDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.saletePriceListItem",
      breadcrumb: [
        "message.purchases",
        "message.registers",
        "message.saletePriceList",
        "message.saletePriceListItem",
        "message.saletePriceItem",
      ],
    },
  },
  {
    path: "/gruposcompras/",
    name: "gruposcompras",
    component: GruposComprasView,
    meta: {
      requiresAuth: true,
      title: "message.purchasingGroup",
      breadcrumb: [
        "message.purchases",
        "message.registers",
        "message.purchasingGroup",
      ],
    },
  },
  {
    path: "/limitesaprovacaocompras/",
    name: "limitesaprovacaocompras",
    component: LimitesAprovacaoView,
    meta: {
      requiresAuth: true,
      title: "message.approvalPurchasingLimits",
      breadcrumb: [
        "message.purchases",
        "message.registers",
        "message.approvalPurchasingLimits",
      ],
    },
  },
  {
    path: "/limitesaprovacaoautomatica/",
    name: "limitesaprovacaoautomatica",
    component: LimitesAprovacaoAutomaticaView,
    meta: {
      requiresAuth: true,
      title: "message.approvalAutomaticPurchaseLimits",
      breadcrumb: [
        "message.purchases",
        "message.registers",
        "message.approvalAutomaticPurchaseLimits",
      ],
    },
  },
];

export default routes;
